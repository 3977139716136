import { isDefined } from "@clipboard-health/util-ts";
import { addDays, endOfDay, isAfter, isBefore } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

export function formatRate(amount?: number): string {
  if (!isDefined(amount)) {
    return "-";
  }

  const rateFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return rateFormatter.format(amount);
}

export function getBookingSchedule(
  prefix: "Shift" | "Interview",
  /**
   * start date in user's timezone
   */
  startDate: Date,
  /**
   * end date in user's timezone
   */
  endDate: Date,
  /**
   * user's timezone
   */
  timezone: string
): string | undefined {
  const now = utcToZonedTime(new Date(), timezone);
  const tomorrow = addDays(now, 1);

  if (isAfter(now, startDate) && isBefore(now, endDate)) {
    return `${prefix} In Progress`;
  }

  if (isBefore(now, startDate) && isBefore(startDate, endOfDay(now))) {
    return "Today";
  }

  if (isBefore(endOfDay(now), startDate) && isBefore(startDate, endOfDay(tomorrow))) {
    return "Tomorrow";
  }

  return undefined;
}
